import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { isEqual } from 'lodash'
import { Navigation } from '@components'
import { useAppSelector } from '@core/store'
import productsApi from '@products/api'
import { ProductHeader } from '@products/components'
import { type PlatformReturnType, PlatformType } from './Products.types'
import { sendPostMessage } from '@core/utils'
import { PLATFORM_MAP } from './Products.constants'
import ProductsFooter from './ProductsFooter'
import ProductsList from './ProductsList'

const Products: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const branch = useAppSelector(state => state.baseSlice.info)
  const cart = useAppSelector(state => state.cartSlice.products)

  const [open, setOpen] = React.useState<string | null>(null)

  const ProductsQuery = productsApi.useCategoriesQuery({ slug: branch?.menu_slug }, { refetchOnMountOrArgChange: true })

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  const categories = React.useMemo(() => ProductsQuery.data?.data, [ProductsQuery])

  const platform = React.useMemo(() => {
    const platformQuery = query.get('platform') as PlatformReturnType

    return get(PLATFORM_MAP, platformQuery, PlatformType.MOBILE)
  }, [query])

  const handleSendPostMessage = React.useCallback(() => {
    sendPostMessage(JSON.stringify({ event: 'BACK_BUTTON_PRESSED' }))
  }, [])

  if (!branch || !categories) return null

  return (
    <React.Fragment>
      <Navigation
        title={t('product.route_header_title')}
        onBackPress={handleSendPostMessage}
        isHide={isEqual(platform, PlatformType.WAAS)}
      />
      <ProductHeader logo={branch.logo} title={branch.name} isHide={isEqual(platform, PlatformType.WAAS)} />
      <ProductsList
        categories={categories}
        cart={cart}
        branch={branch}
        open={open}
        setOpen={setOpen}
        isHide={isEqual(platform, PlatformType.WAAS)}
      />
      <ProductsFooter cart={cart} branchSlug={branch.branch_slug} />
    </React.Fragment>
  )
}
export default Products
