import type { InfoItem } from '@core/api'
import type { ProductCartType } from '@cart/slice'
import type { CategoriesResponse } from '@products/api'

export interface ProductsFooterProps {
  cart: ProductCartType[]
  branchSlug: string
}

export interface ProductsListProps {
  categories: CategoriesResponse['data']
  cart: ProductCartType[]
  branch: InfoItem
  isHide?: boolean
  open: string | null
  setOpen: (value: string | null) => void
}

export type DeliveryTypeProps = 'table' | 'address' | 'takeaway'

export enum PlatformType {
  WAAS = 'waas',
  MOBILE = 'mobile',
}
export type PlatformReturnType = 'waas' | 'mobile'
