import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { filter, map, sumBy } from 'lodash'
import { BottomSheet, ProductCard, SectionBar } from '@components'
import { ProductDetail } from '@products/views'
import type { ProductsListProps } from './Products.types'
import { getDeliveryPrice, getProductsListPadding } from './Products.utils'
import { cn, Modules } from '@core/utils'

const ProductsList: React.FC<ProductsListProps> = ({ categories, cart, branch, isHide, open, setOpen }) => {
  const location = useLocation()

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  const isModules = React.useMemo(() => {
    return Modules.getIsModules(query, branch)
  }, [branch, query])

  return (
    <div
      className="container space-y-4"
      style={{ paddingBottom: getProductsListPadding(cart.length, branch?.settings.min_order_amount.amount) }}>
      {map(categories, (category, index) => (
        <div key={index}>
          <SectionBar
            title={category.name}
            description={category.description}
            className={cn(
              'sticky top-0 z-40 bg-tint-white-60 py-6 backdrop-blur',
              !isHide && '!top-[var(--navigation-height)]',
            )}
          />
          <div className="space-y-2">
            {map(category.products, (product, index) => (
              <BottomSheet
                key={index}
                open={open === product.slug}
                onOpenChange={isOpen => setOpen(isOpen ? product.slug : null)}>
                <BottomSheet.Trigger className="w-full outline-none">
                  <ProductCard
                    title={product.name}
                    description={product.description}
                    price={getDeliveryPrice(query, branch, product) as number}
                    image={product.list_image_url}
                    isModules={isModules}
                    orderCount={sumBy(filter(cart, { slug: product.slug }), 'quantity')}
                    IconButtonProps={{ as: 'div' }}
                  />
                </BottomSheet.Trigger>
                <BottomSheet.Content className="mx-[-1px] h-full max-h-[95%] outline-none">
                  <ProductDetail
                    menuSlug={branch.menu_slug}
                    categorySlug={category.slug}
                    productSlug={product.slug}
                    setOpen={setOpen}
                  />
                </BottomSheet.Content>
              </BottomSheet>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
export default ProductsList
